import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import {
  SectionSmallHeading,
  SectionSubHeading,
  SectionWrapper,
} from "../../../styles";

const Div = styled.div`
  background-color: #202020;
  display: flex;
  justify-content: center;
  padding: 100px 0px;
  @media screen and (max-width: 599px) {
    padding: 0px 0;
  }
`;
const DivBG = styled.div`
  background-image: url("../../../images/socialbf.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0;
  @media screen and (max-width: 799px) {
    padding: 20px 0;
    background-position: right;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
  }
  @media screen and (max-width: 599px) {
    padding: 50px 0;
    background-position: right;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
  }
`;
const Headings = styled.a`
  display: flex;
  place-content: center;
  padding: 5px 25px;
  background-color: #FDFE00;
  border-radius: 30px;
  text-align: center;
  align-items: center;
  color: #000;
  margin-right: 20px;
  @media screen and (max-width: 599px) {
    margin: 20px 0;
  }
`;
const Socials = styled.div`
  display: flex;
  @media screen and (max-width: 599px) {
    display: grid;
    margin: 20px 0;
  }
`;
const CommunityImage = styled.img`
max-width: 100%;
height: auto;
`;

const index = () => {
  return (
    <Div id="socials">
      <Container maxWidth="xl">
        <DivBG>
          <SectionWrapper
            p599="50px 20px"
            p799="20px 30px"
            p="50px 120px"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in"
            // data-aos-delay="500"
            // data-aos-offset="0"
            data-aos-duration="1500"
          >
            <SectionSmallHeading fs="50px" p="50px 0">
              Join Our Community
            </SectionSmallHeading>
            <Socials>
              <Headings
                href="https://twitter.com/Brrrrblast"
                target="blank"
              >
                <CommunityImage
                  src="../../../images/twitter.png"
                />
              </Headings>
              <Headings href="https://t.me/brrrrblast" target="blank">
                <CommunityImage
                  src="../../../images/telegram.png"
                />
              </Headings>
              
            </Socials>
          </SectionWrapper>
        </DivBG>
      </Container>
    </Div>
  );
};

export default index;
