import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import { Heading, P, SectionWrapper } from "../../../styles";

const Div = styled.div`
  padding: 120px 100px;
  background-color: #FDFE00;
  text-align: center;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1199px) {
    padding: 54px 2px;
  }
  @media screen and (max-width: 899px) {
    padding: 0px 10px;
  }
`;
const VideoBK = styled.video`
  border-radius: 30px;
  width: 100%;
  height: 600px;
  object-fit: cover;
  z-index: -100;
  @media screen and (max-width: 599px) {
    height: 300px;
  }
`;
const VideoGrid = styled(Grid)``;
const GridText = styled(Grid)`
  margin-left: 30px;
  @media screen and (max-width: 599px) {
    padding-top: 20px;
  }
`;
const Grids = styled(Grid)`
  align-items: center;
  display: flex;
  background-color: #000000;
  padding: 0px 0;
  border-radius: 20px !important;
`;
const Lines = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0;
`;
const Slipage = styled.div`
  box-shadow: ${(props) =>
    props.bs ? props.bs : "3px 3px 7px 1px #00000017;"};
  display: flex;
  align-items: center;
  padding: 0px 0;
  margin: 20px 0 0 0;
  border-radius: 20px 20px 20px 20px;
  background-color: #1A1A1A;
  max-width: 40%;
  @media screen and (max-width: 1399px) {
    max-width: 60%;
  }
  @media screen and (max-width: 599px) {
    max-width: 70%;
  }
`;
const TOkenomicsData = styled.div`
  padding: 20px 0 0 0;
`;

const TokenomicsImage = styled.img`
width: 100%;
height: auto;
max-width: 20px;
`;
const TokenomicsLastImage = styled.img`
width: 100%;
height: auto;
max-width: 40px;
`;
const BrrImage = styled.img`
width: 100%;
height: auto;
max-width: 360px;
`;
const Index = () => {
  return (
    <Div id="tokenomics">
      <Container maxWidth="xl">
        <SectionWrapper
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in"
          data-aos-delay="400"
          data-aos-duration="400"
          data-aos-offset="0"
        >
          <Grids container>
            <BrrImage  src="../../../images/1brrr.png" />
            <GridText item xs={12} md={6}>
              <Heading
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in"
                data-aos-delay="500"
                data-aos-duration="500"
                data-aos-offset="0"
              >
                $BRRRRR!
                <br /> Tokenomics
              </Heading>
              <P m="10px 0" fw="600" p="0 0 0 10px" color="#fff">
              6% tax on transactions, 2% for development, and 4% ETH rewards to holders, providing a symphony of passive income opportunities. 
                  </P>
              <TOkenomicsData>
                <Lines
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in"
                  data-aos-delay="700"
                  data-aos-duration="700"
                  data-aos-offset="0"
                >
                  <TokenomicsImage
                    src="../../../images/recangle.png"
                  />
                  <P m="10px 0" fw="600" p="0 0 0 10px" color="#FDFE00">
                  Ethereum Reflections (4%)
                  </P>
                </Lines>
                <Lines
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in"
                  data-aos-delay="700"
                  data-aos-duration="700"
                  data-aos-offset="0"
                >
                  <TokenomicsImage
                    src="../../../images/recangle.png"
                  />
                  <P m="10px 0" fw="600" p="0 0 0 10px" color="#FDFE00">
                  Marketing/Development (2%)
                  </P>
                </Lines>
                {/* <Lines
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in"
                  data-aos-delay="700"
                  data-aos-duration="700"
                  data-aos-offset="0"
                >
                  <TokenomicsImage
                    src="../../../images/recangle.png"
                  />
                  <P m="10px 0" fw="600" p="0 0 0 10px" color="#24314A">
                    Dev (1%)
                  </P>
                </Lines>
                <Lines
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in"
                  data-aos-delay="700"
                  data-aos-duration="700"
                  data-aos-offset="0"
                >
                  <TokenomicsImage
                    src="../../../images/recangle.png"
                  />
                  <P m="10px 0" fw="600" p="0 0 0 10px" color="#24314A">
                    Sells 6%
                  </P>
                </Lines>
                <Lines
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in"
                  data-aos-delay="700"
                  data-aos-duration="700"
                  data-aos-offset="0"
                >
                  <TokenomicsImage
                    src="../../../images/recangle.png"
                  />
                  <P m="10px 0" fw="600" p="0 0 0 10px" color="#24314A">
                    ETH Dividend (4%)
                  </P>
                </Lines>
                <Lines
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in"
                  data-aos-delay="700"
                  data-aos-duration="700"
                  data-aos-offset="0"
                >
                  <TokenomicsImage
                    src="../../../images/recangle.png"
                  />
                  <P m="10px 0" fw="600" p="0 0 0 10px" color="#24314A">
                    Dev (2%)
                  </P>
                </Lines> */}

                <Slipage
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in"
                  data-aos-delay="1000"
                  data-aos-duration="1000"
                  data-aos-offset="0"
                >
                  <TokenomicsLastImage
                    src="../../../images/percent.png"
                  />
                  <P m="10px" fw="600" p="0 0 0 10px" color="#FDFE00">
                 {" Slippage> 6%"}
                  </P>
                </Slipage>
              </TOkenomicsData>
            </GridText>
          </Grids>
        </SectionWrapper>
      </Container>
    </Div>
  );
};

export default Index;
