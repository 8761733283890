import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import {
  P,
  SectionHeading,
  SectionSubHeading,
  SectionWrapper,
} from "../../../styles";
const DIv = styled.div`
  text-align: left;
`;
const Background = styled.div`
  background: url("../../../images/featuresbf.png");

  place-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  text-align: center;
  align-items: center;
  @media screen and (max-width: 899px) {
    padding: 100px 0 0 0;
  }
`;
const Headings = styled.div`
  display: flex;
  place-content: center;
  padding: 20px 100px;
  background-color: #171717;
  border-radius: 30px;
  text-align: center;
  align-items: center;
  color: #000;
  @media screen and (max-width: 1099px) {
    padding: 20px 54px;
  }
  @media screen and (max-width: 599px) {
    padding: 20px 54px;
  }
`;
const FeatureImage = styled.img`
max-width: 100%;
height: auto;
`;

const index = () => {
  return (
    <div id="features">
      <Background>
        <SectionWrapper p="100px 0">
          <Container maxWidth="xl">
            <SectionHeading
              lh599="55px"
              fs599="60px"
              color="#FDFE00"
              p="150px 0"
              align="center"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in"
              data-aos-delay="500"
              data-aos-duration="500"
              data-aos-offset="0"
            >
              $BRRRRR! Protocol <br />
              Features
            </SectionHeading>
            <br />
            <br />
            <br />
            <Grid container spacing={6}>
              <Grid item xs={12} md={4}>
                <Headings
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in"
                  data-aos-delay="600"
                  data-aos-duration="600"
                  data-aos-offset="0"
                >
                  <FeatureImage
                    src="../../../images/safecontract.png"
                  />
                </Headings>
              </Grid>
              <Grid item xs={12} md={4}>
                <Headings
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in"
                  data-aos-delay="700"
                  data-aos-duration="700"
                  data-aos-offset="0"
                >
                  <FeatureImage
                    src="../../../images/lockedliquidity.png"
                  />
                </Headings>
              </Grid>
              <Grid item xs={12} md={4}>
                <Headings
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in"
                  data-aos-delay="800"
                  data-aos-duration="800"
                  data-aos-offset="0"
                >
                  <FeatureImage
                    src="../../../images/marketingwallet.png"
                  />
                </Headings>
              </Grid>
              <Grid item xs={12} md={4}>
                <Headings
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in"
                  data-aos-delay="800"
                  data-aos-duration="800"
                  data-aos-offset="0"
                >
                  <FeatureImage
                    src="../../../images/hold.png"
                  />
                </Headings>
              </Grid>
              <Grid item xs={12} md={4}>
                <Headings
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in"
                  data-aos-delay="700"
                  data-aos-duration="700"
                  data-aos-offset="0"
                >
                  <FeatureImage
                    src="../../../images/reward.png"
                  />
                </Headings>
              </Grid>
              <Grid item xs={12} md={4}>
                <Headings
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in"
                  data-aos-delay="600"
                  data-aos-duration="600"
                  data-aos-offset="0"
                >
                  <FeatureImage
                    src="../../../images/dapp.png"
                  />
                </Headings>
              </Grid>
            </Grid>
          </Container>
        </SectionWrapper>
      </Background>
    </div>
  );
};

export default index;
