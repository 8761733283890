import styled from "@emotion/styled";
import { Container, Link } from "@mui/material";

const MenuContainer = styled.div`
  width: 100%;
  z-index: 9999;
  transition: all linear 0.3s;
  position: ${(props) => (props.isSticky == "sticky" ? "fixed" : "absolute")};
  background-color: ${(props) => (props.isSticky == "sticky" ? "black" : "")};
  padding: ${(props) => (props.isSticky == "sticky" ? "" : "15px 0")};
  box-shadow: ${(props) =>
    props.isSticky == "sticky" ? "0px 3px 7px rgba(0, 0, 0, 0.51)" : ""};
  img {
    width: ${(props) => (props.isSticky == "80px !important" ? "" : "")};
  }
`;
const DesktopMainMenu = styled(Container)`
  padding: 1px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 899px) {
    display: none;
  }
`;
const MobileMainMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;

  @media (min-width: 900px) {
    display: none;
  }
`;
const SiteLogo = styled.img`
  max-width: 100%;
  height: auto;
`;
const MenuLink = styled(Link)`
  align-items: center !important;
  font-size: max(15px, 17px);
  font-family: "poppins-light";
  font-weight: bolder;
  color: rgba(255, 255, 255, 1);
  text-transform: camelcase;
  text-decoration: none;
  cursor: pointer;
  transition-duration: 0.5s;
  &:nth-child(5) {
    background-color: transparent;
    border-radius: 25px;
    padding: 10px 50px 10px 50px;
    font-family: "Poppins-Regular";
    color: #FDFE00;
    font-size: 14px;
    border: 1px solid #FDFE00;
    /* background: linear-gradient(to top, #f4a703, #ffca1b); */
    /* box-shadow: ${(props) =>
      props.bs ? props.bs : "inset 0px 2px 5px 0px #FED0AE"}; */
    &:hover {
      opacity: 0.8;
    }
    @media screen and (max-width: 899px) {
      display: none;
    }
  }
  &:hover,
  &:active,
  &:focus {
    color: #FDFE00;
    transition-duration: 0.5s;
  }
  @media (max-width: 899px) {
    display: block;
    padding: 15px 0 15px 2%;
    border-bottom: 1px solid #1a1a1a;
    text-align: left;

    &:nth-child(1) {
      padding-top: 25px;
    }
  }
  &:nth-child(6) {
    padding: 0px;
    border-width: 0px;
  }
`;

export { MenuContainer, DesktopMainMenu, MobileMainMenu, MenuLink, SiteLogo };
