import "./App.css"
import React, { memo } from "react";
import S1_Header from '../src/pages/MainPage/S1_Header/index';
import S2_Aboutus from '../src/pages/MainPage/S2_Aboutus/index';
import S3_Tokenomics from '../src/pages/MainPage/S3_Tokenomics/index'
import S4_Features from '../src/pages/MainPage/S4_Features/index'
import S5_Community from '../src/pages/MainPage/S5_Community/index'
import S6_Footer from '../src/pages/MainPage/S6_Footer/index'

const App = () => {
  return (
    <div>
      <S1_Header />
      <S2_Aboutus />
      <S3_Tokenomics />
      <S4_Features />
      <S5_Community />
      <S6_Footer />
    </div>
  );
};

export default memo(App);

