import styled from "@emotion/styled";

const SectionWrapper = styled.div`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "")};
  padding: ${(props) => (props.p ? props.p : "0")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.align ? props.align : "")};
  @media screen and (max-width: 799px) {
    padding: ${(props) => (props.p799 ? props.p799 : "30px 0")};
  }
  @media screen and (max-width: 599px) {
    padding: ${(props) => (props.p599 ? props.p599 : "30px 0")};
  }
`;
const P = styled.p`
  font-weight: ${(props) => (props.fw ? props.fw : "")};
  font-family: "Poppins-Regular";
  font-size: ${(props) => (props.fs ? props.fs : "18px")};
  color: ${(props) => (props.color ? props.color : "#fff")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  text-align: ${(props) => (props.align ? props.align : "left")};
  margin: ${(props) => (props.m ? props.m : "20px 0")};
  padding: ${(props) => (props.p ? props.p : "")};
  @media (max-width: 600px) {
    /* font-size: 14px; */
    max-width: 100%;
  }
  @media (max-width: 1440px) and (max-height: 620px) {
    padding-top: ${(props) => (props.pt630 ? props.pt630 : "0px")};
  }
`;
const CustomContainer = styled.div`
  max-width: 2600px;
`;
const Heading = styled.div`
  text-transform: uppercase;
  font-size: ${(props) => (props.fs ? props.fs : "55px")};
  color: ${(props) => (props.color ? props.color : "#FDFE00")};
  padding: ${(props) => (props.p ? props.p : "")};

  line-height: ${(props) => (props.lh ? props.lh : "60px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  text-align: ${(props) => (props.align ? props.align : "left")};
  font-family: "Poppins-SemiBold";

  @media (max-width: 1199px) {
    font-size: 50px;
  }
  @media (max-width: 599px) {
    font-size: 30px;
    line-height:30PX;
  }
`;
const SectionHeading = styled.h2`
  color: ${(props) => (props.color ? props.color : "#FDFE00")};
  font-size: ${(props) => (props.fs ? props.fs : "78px")};
  font-family: "Poppins-SemiBold";
  padding: ${(props) => (props.p ? props.p : "10px 0")};
  line-height: 80px;
  margin: 0;
  padding: 0;
  font-weight: 200;
  margin-top: ${(props) => (props.m ? props.m : "")};
  @media screen and (max-width: 1199px) {
    font-size: 50px;
  }
  @media screen and (max-width: 599px) {
    font-size: ${(props) => (props.fs599 ? props.fs599 : "55px")};
    line-height: ${(props) => (props.lh599 ? props.lh599 : "")};
  }
`;
const SectionSubHeading = styled.h3`
  font-family: "Hanson";
  text-transform: uppercase;
  font-size: ${(props) => (props.fs ? props.fs : "55px")};
  color: ${(props) => (props.color ? props.color : "#fff")};
  padding: ${(props) => (props.p ? props.p : "0px 0 0px 0")};
  margin: ${(props) => (props.m ? props.m : "0")};
  line-height: ${(props) => (props.lh ? props.lh : "50px")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  text-align: ${(props) => (props.align ? props.align : "")};

  @media (max-width: 599px) {
    line-height: ${(props) => (props.lh ? props.lh : "35px")};
    max-width: 100%;
    font-size: ${(props) => (props.fs599 ? props.fs599 : "35px")};
  }
`;
const SectionSmallHeading = styled.h3`
  font-family: "Poppins-SemiBold";
  font-size: ${(props) => (props.fs ? props.fs : "40px")};
  /* text-transform: ${(props) => (props.tt ? props.tt : "uppercase")}; */
  color: ${(props) => (props.color ? props.color : "#FDFE00")};
  padding: ${(props) => (props.p ? props.p : "0px 0 0px 0")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* line-height: ${(props) => (props.lh ? props.lh : "40px")}; */
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  text-align: ${(props) => (props.align ? props.align : "")};

  @media (max-width: 799px) {
    padding: ${(props) => (props.p799 ? props.p799 : "")};
    line-height: ${(props) => (props.lh ? props.lh : "")};
    max-width: 100%;
    font-size: ${(props) => (props.fs599 ? props.fs599 : "40px")};
  }
  @media (max-width: 599px) {
    line-height: ${(props) => (props.lh ? props.lh : "")};
    max-width: 100%;
    font-size: ${(props) => (props.fs599 ? props.fs599 : "")};
  }
`;

export {
  SectionWrapper,
  P,
  SectionHeading,
  SectionSubHeading,
  CustomContainer,
  SectionSmallHeading,
  Heading,
};
