import React, { useEffect, useRef, useState } from "react";
import {
  Heading,
  P,
  SectionHeading,
  SectionSmallHeading,
  SectionSubHeading,
  SectionWrapper,
} from "../../../styles";
import Menu from "./menu";
import styled from "@emotion/styled";
import { Container } from "@mui/material";
import Aos from "aos";
import "aos/dist/aos.css";
const Main = styled.div``;
const Background = styled.div`
  background-image: url('../../../images/mainbg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  text-align: center;
  align-items: center;
  @media screen and (max-width: 899px) {
    padding: 100px 0 0 0;
  }
`;
const ReadMore = styled.a`
  text-decoration: none;
  cursor: pointer;
  background: linear-gradient(to top, #f4a703, #ffca1b);
  box-shadow: ${(props) =>
    props.bs ? props.bs : "inset 0px 2px 5px 0px #fff"};
  color: ${(props) => (props.color ? props.color : "#990e2f")};
  margin-right: 20px;
  border-radius: 20px;
  padding: 8px 40px;
  font-family: "Poppins-Regular";
  font-size: 14px;
  @media screen and (max-width: 899px) {
    padding: ${(props) => (props.p599 ? props.p599 : "5px 27px")};
  }

  /* box-shadow: ${(props) =>
    props.bs ? props.bs : "inset 0px 2px 5px 0px #FED0AE"}; */
  &:hover {
    opacity: 0.9;
  }
`;
const Chart = styled.a`
  text-decoration: none;
  cursor: pointer;
  background-color: #FDFE00;
  box-shadow: ${(props) =>
    props.bs ? props.bs : "inset 0px 3px 4px 1px #ffffff40"};
  color: ${(props) => (props.color ? props.color : "#000")};
  margin-right: 20px;
  border-radius: 20px;
  padding: 8px 40px;
  font-family: "Poppins-Regular";
  font-size: 14px;
  @media screen and (max-width: 899px) {
    padding: ${(props) => (props.p599 ? props.p599 : "5px 27px")};
  }

  /* box-shadow: ${(props) =>
    props.bs ? props.bs : "inset 0px 2px 5px 0px #FED0AE"}; */
  &:hover {
    opacity: 0.9;
  }
`;
const Bottom = styled.div`
  /* position: absolute;
  bottom: -20px;
  left: 0;
  right: 0; */
  @media screen and (max-width: 599px) {
    /* bottom: -90px; */
  }
`;
const BottombUTTON = styled.div`
  padding: 30px 0 20px 0;
  @media screen and (max-width: 599px) {
    /* bottom: -80px; */
  }
`;
const HeadingImage = styled.div`
 padding: 0px 0;
  margin: 120px 0 0 0;
  @media screen and (max-width: 599px) {
    margin: 50px 0 0 0;
  }
`;

const TransactionBuyLinks = styled.div`
  background-color: #191919;
  padding: 20px 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  `;
const Dextool = styled.a`
  padding: 0px 30px;
  @media screen and (max-width:450px){
    padding: 0px 10px;

  }
  `;
const Uniswap = styled.a`
  padding: 0px 30px;
  @media screen and (max-width:450px){
    padding: 0px 10px;

  }

`;
const Etherscan = styled.a`
  padding: 0px 30px;
  @media screen and (max-width: 599px) {
    padding: 0px 10px;
  }
`;

const Textarea = styled.textarea`
  padding: 28px 0 0 0;
  resize: none;
  overflow: hidden;
  color: #000;
  border: none;
  background: transparent;
  text-align: -webkit-center;
  /* max-width: 444px; */
  max-width: 44px;
  width: 100%;
  outline: none;
  @media screen and (max-width: 1200px) {
    /* max-width: 365px; */
  }
`;
const ContractAdd = styled.span`
  word-break: keep-all;
  place-content: center;
  display: flex;
  align-items: CENTER;
  color: #000;
  @media screen and (max-width: 1200px) {
    /* display: grid; */
  }
`;
const Contract = styled.div`
  /* padding: 30px 0; */
  text-align: center;
  font-size: 20px;
  letter-spacing: 2px;
  font-family: "Poppins-Bold";
  color: #000;
  background-color: #FDFE00;
  /* background: linear-gradient(to top, #f4a703, #ffca1b); */
`;

const HeaderImage = styled.img`
max-width: 500px;
height: auto;
width:100%;
`;
const HeaderSubImage = styled.img`
width: 100%;
height: auto;
max-width: 180px;

`;

const Index = () => {
  const [copySuccess, setCopySuccess] = useState("");
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
    setCopySuccess("Copied!");
  }
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.addEventListener("load", function () {
      Aos.init();
    });
  }, []);
  return (
    <div>
      <Menu />
      <Background>
        <Container maxWidth="xl">
          <HeadingImage
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in"
            data-aos-delay="500"
            data-aos-duration="500"
            data-aos-offset="0"
          >
            <HeaderImage
              src="../../../images/brrimage.png"
            />
          </HeadingImage>
          <SectionSmallHeading
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in"
            data-aos-delay="600"
            data-aos-duration="600"
            data-aos-offset="0"
          >
            The protocol to give you $ETH dividend
          </SectionSmallHeading>
          <P
            align="center"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in"
            data-aos-delay="700"
            data-aos-duration="700"
            data-aos-offset="0"
          >
            $ETH reflections given back to every holder, on every buy and sell
<br />transaction via taxes.
          </P>
          <SectionSmallHeading
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in"
            data-aos-delay="800"
            data-aos-duration="800"
            data-aos-offset="0"
          >
            Hold and Earn $ETH
          </SectionSmallHeading>
          <BottombUTTON
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in"
            data-aos-delay="900"
            data-aos-duration="900"
            data-aos-offset="0"
          >
            <Chart
              color="#000"
              href="https://t.me/brrrrblast"
              target="_blank"
            >
              Join Telegram
            </Chart>
          </BottombUTTON>
        </Container>
      </Background>
      <TransactionBuyLinks>
        <Dextool
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in"
          data-aos-delay="500"
          data-aos-duration="500"
          data-aos-offset="0"
        >
          <HeaderSubImage
            src="../../../images/dex.png"

          />
        </Dextool>
        <Uniswap
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in"
          data-aos-delay="600"
          data-aos-duration="600"
          data-aos-offset="0"
        >
          <HeaderSubImage
            src="../../../images/uni.png"
          />
        </Uniswap>
        <Etherscan
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in"
          data-aos-delay="700"
          data-aos-duration="700"
          data-aos-offset="0"
        >
          <HeaderSubImage
            src="../../../images/eth.png"
          />
        </Etherscan>
      </TransactionBuyLinks>

      <Contract
        data-aos="fade-zoom-in"
        data-aos-easing="ease-in"
        data-aos-delay="800"
        data-aos-duration="800"
        data-aos-offset="0"
      >
        <ContractAdd>
          Contract:
          <Textarea ref={textAreaRef} value="TBA" />
          {/* <img
            onClick={copyToClipboard}
            style={{ cursor: "pointer" }}
            src="../../../images/copy.png"
          /> */}
          {/* {copySuccess} */}
        </ContractAdd>
      </Contract>
    </div>
  );
};

export default Index;
