import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import { P, SectionWrapper } from "../../../styles";
const SocialsMediaDiv = styled.div`
  padding: 30px 0 20px 0;
  text-align: right;
  @media screen and (max-width: 899px) {
    text-align: LEFT;
  }
`;
const SocialsDIv = styled.div`
  display: flex;
  align-items: center;
`;
const SocialLinks = styled.a`
  padding: 0 20px;
  text-decoration: none;
`;
const SocialLinksApp = styled.a`
  text-decoration: none;
  padding: 4px 35px;
  border: 1px solid #FDFE00;
  color: #FDFE00;
  border-radius: 30px;
`;

const MainDiv = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #FDFE00;
  @media screen and (max-width: 799px) {
    display: none;
  }
`;
const Copy = styled.div`
  padding: 20px 0;
  text-align: center;
  color: #FDFE00;
  @media screen and (max-width: 799px) {
    border-top: 1px solid #FDFE00;
  }
`;
const FooterImage = styled.img`
width: 100%;
height: auto;
max-width:200px;
`;

const LogoImage = styled.a``;
const index = () => {
  return (
    <div>
      <SectionWrapper bgColor="#202020">
        <Container maxWidth="xl">
          <MainDiv>
            <LogoImage>
              <FooterImage
                src="../../../images/logobrrlatest.png"
              />
            </LogoImage>
            <SocialsDIv>
              <SocialLinks href="/#aboutus">About us </SocialLinks>
              <SocialLinks href="/#tokenomics">Tokenomics </SocialLinks>
              <SocialLinks href="/#features">Features </SocialLinks>
              <SocialLinks href="/#socials">Socials </SocialLinks>
              <SocialLinksApp href="https://t.me/brrrrblast" target="_blank">Telegram </SocialLinksApp>
            </SocialsDIv>
          </MainDiv>
          <Copy>Copyright © 2024 BRRR PROTOCOL</Copy>
        </Container>
      </SectionWrapper>
    </div>
  );
};

export default index;
