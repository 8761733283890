import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import logo from "./logo.png";
import { DesktopMainMenu, MenuLink } from "./styles";

const Div = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-width: 45%;
  word-break: keep-all;
  @media screen and (max-width: 1260px) {
    min-width: 50%;
  }
  @media screen and (max-width: 1055px) {
    min-width: 60%;
  }
`;
const LogoImage = styled.img`
max-width: 100%;
height: auto;
`

const DesktopMenu = (props) => {
  const menulist = props.menuList.map(function (value, i) {
    return (
      <MenuLink key={i} href={value[1]}>
        {value[0]}
      </MenuLink>
    );
  });
  return (
    <DesktopMainMenu maxWidth="xl">
      <MenuLink href="/">
        <LogoImage src={logo} width="162" height="72" layout="intrinsic" />
      </MenuLink>
      <Div>{menulist}</Div>
    </DesktopMainMenu>
  );
};

export default DesktopMenu;
