import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import { P, SectionHeading, SectionWrapper } from "../../../styles";

const GridHEading = styled(Grid)`
  padding: 100px 0;
  align-items: end;
  place-content: ${(props) => (props.pc ? props.pc : "")};
`;
const GridImage = styled(Grid)`
  place-content: ${(props) => (props.pc ? props.pc : "center")};
  text-align: center;
`;
const ReadMore = styled.a`
  text-decoration: none;
  cursor: pointer;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "#fff")};
  color: ${(props) => (props.color ? props.color : "#FDFE00")};
  border: ${(props) => (props.border ? props.border : "1px solid #000000")};
  margin-right: 20px;
  border-radius: 20px;
  padding: 8px 40px;
  font-family: "Poppins-Regular";
  font-size: 14px;
  @media screen and (max-width: 899px) {
    padding: ${(props) => (props.p599 ? props.p599 : "5px 27px")};
  }

  /* box-shadow: ${(props) =>
    props.bs ? props.bs : "inset 0px 2px 5px 0px #FED0AE"}; */
  &:hover {
    opacity: 0.9;
  }
`;

const AboutImage = styled.img`
width: 100%;
height: auto;
max-width:300px;
`;

const index = () => {
  return (
    <div>
      <SectionWrapper p="2rem 0" id="aboutus" bgColor="#1A1A1A">
        <Container maxWidth="xl">
          <GridHEading container spacing={3}>
            <Grid xs={12} md={6} item>
              <SectionHeading
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in"
                data-aos-delay="500"
                data-aos-duration="500"
                data-aos-offset="0"
              >
                About us
              </SectionHeading>
              <P
                maxWidth="70%"
                p="20px 0"
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in"
                data-aos-delay="700"
                data-aos-duration="700"
                data-aos-offset="0"
              >
                Welcome to BRRRR, a global financial revolution with a unique focus on passive income through holding. Our vision revolves around democratizing finance, ensuring equal access to decentralized financial benefits with core values of integrity and innovation.
<br /><br />
Whether you're a seasoned crypto enthusiast or a newcomer, BRRRR welcomes you to join our revolution
              </P>
              <ReadMore
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in"
                data-aos-delay="800"
                data-aos-duration="800"
                data-aos-offset="0"
                color="#000000"
                bgcolor="#FDFE00"
                href="/"
                target="_blank"
              >
                Read More
              </ReadMore>
            </Grid>
            <GridImage item xs={12} md={6}>
              <AboutImage
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in"
                data-aos-delay="900"
                data-aos-duration="900"
                data-aos-offset="0"
                src="../../../images/aboutimg.png"
              />
            </GridImage>
          </GridHEading>
        </Container>
      </SectionWrapper>
    </div>
  );
};

export default index;
