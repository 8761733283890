import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";

import DesktopMenu from "./deskTopMenu";
import MobileMenu from "./mobileMenu";
import { MenuContainer } from "./styles";

const MenuList = [
  ["About Us", "/#aboutus"],
  ["Tokenomics", "/#tokenomics"],
  ["Features", "/#features"],
  ["Socials", "/#socials"],
  ["Telegram", "/https://t.me/brrrrblast"],
];

const checkSticky = "";
const Menu = () => {
  // const [useCheckSticky, setCheckSticky] = useState("no-sticky");
  // useEffect(() => {
  //   window.addEventListener("scroll", isSticky);
  //   return () => {
  //     window.removeEventListener("scroll", isSticky);
  //   };
  // });
  // const isSticky = (e) => {
  //   const scrollTop = window.scrollY;
  //   scrollTop >= 20 ? setCheckSticky("sticky") : setCheckSticky("No-sticky");
  // };
  return (
    <MenuContainer>
      <DesktopMenu menuList={MenuList} />
      <MobileMenu menuList={MenuList} />
    </MenuContainer>
  );
};

export default Menu;
